import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Typography } from '@material-ui/core';
import { ReactComponent as Plus } from 'resources/icons/plus.svg';
import styles from './TopBarStyle';

class Addbtn extends Component {
  showModal = (e) => {
    e.stopPropagation();
    const { actions, location } = this.props;
    let modalKey = '';
    let params = {};
    console.log(location.pathname); // eslint-disable-line
    switch (location.pathname) {
      case '/supply-receipts':
        params = { type: 'add', action: 'add' };
        modalKey = 'add-supply-receipts';
        actions.ui.openModal({ modalKey, params });
        break;
      case '/order-fulfillment':
        params = { type: 'add', action: 'add' };
        modalKey = 'modal-order';
        actions.ui.openModal({ modalKey, params });
        break;
      case '/third-party':
        params = { type: 'thirdparty', action: 'add' };
        modalKey = 'modal-order';
        actions.ui.openModal({ modalKey, params });
        break;
      default:
        break;
    }
  };

  render() {
    const { classes, searchType, location } = this.props;
    let addTitle = 'Create';
    if (location.pathname === '/third-party') {
      addTitle = 'Add New';
    }
    let stype = '';
    if (typeof searchType !== 'undefined') {
      stype = searchType;
    }

    return (
      <div className={`${classes.addbtn} ${stype}btn`} onClick={this.showModal}>
        <IconButton className={classes.iconbtn} aria-label="Add">
          <Plus
            className={classes.icon}
          />
        </IconButton>
        <Typography className={`${classes.addtitle} ${stype}`} component="span">
          {addTitle}
        </Typography>
      </div>
    );
  }
}

Addbtn.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  actions: PropTypes.instanceOf(Object).isRequired,
  searchType: PropTypes.string, // eslint-disable-line
  location: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(Addbtn);
