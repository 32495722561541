/* eslint-disable max-len */

import axios from 'axios';
import { wmsheader } from 'state/logged-user/actions';
import { SITE_SERVER } from './misc';

if (process.env.NODE_ENV !== 'development') {
  axios.defaults.withCredentials = true;
}

export const requestOrders = (
  clientId, type, limit, page, text, start, end, name, code, company, email, sortColumn, sortType,
  exceptOrderStatusNew,
) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/orders?${clientId !== null && typeof clientId !== 'undefined' ? `&client_id=${clientId}&` : ''}limit=${limit}&page=${page}${type !== null ? `&type=${type}` : ''}${text !== null ? `&text=${text}` : ''}${start !== null ? `&order_date_start=${start}` : ''}${end !== null ? `&order_date_end=${end}` : ''}${name !== null ? `&recipient_name=${name}` : ''}${code !== null ? `&carrier_code=${code}` : ''}${company !== null ? `&company_name=${company}` : ''}${email !== null ? `&recipient_email=${email}` : ''}${sortColumn !== null ? `&sort_column=${sortColumn}` : ''}${sortType !== null ? `&sort_type=${sortType}` : ''}${exceptOrderStatusNew !== null ? `&except_order_status_new=${exceptOrderStatusNew}` : ''}`,
  headers: wmsheader(),
});

export const requestReturnOrders = (
  clientId, status, limit, page, text, start, end, name, company, email,
  sortColumn, sortType,
) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/returnorders?${clientId !== null && typeof clientId !== 'undefined' ? `&client_id=${clientId}&` : ''}status=${status}&limit=${limit}&page=${page}${text !== null ? `&text=${text}` : ''}${start !== null ? `&order_date_start=${start}` : ''}${end !== null ? `&order_date_end=${end}` : ''}${name !== null ? `&recipient_name=${name}` : ''}${company !== null ? `&company_name=${company}` : ''}${email !== null ? `&recipient_email=${email}` : ''}${sortColumn !== null ? `&sort_column=${sortColumn}` : ''}${sortType !== null ? `&sort_type=${sortType}` : ''}`,
  headers: wmsheader(),
});

export const requestVendorOrders = (clientId, limit, page, text, sortColumn, sortType) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/vendorders?${clientId !== null && typeof clientId !== 'undefined' ? `&client_id=${clientId}&` : ''}limit=${limit}&page=${page}${text !== null ? `&text=${text}` : ''}${sortColumn !== null ? `&sort_column=${sortColumn}` : ''}${sortType !== null ? `&sort_type=${sortType}` : ''}`,
  headers: wmsheader(),
});

export const requestOrderDetails = id => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/orders/${id}`,
  headers: wmsheader(),
});

export const requestOrderItems = ranumber => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/returnorders/${ranumber}`,
  headers: wmsheader(),
});

export const requestVendorOrderDetails = ponumber => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/vendorders/${ponumber}`,
  headers: wmsheader(),
});

export const postSalesOrder = (dataToSubmit, id) => axios({
  method: id !== null ? 'PUT' : 'POST',
  url: `${SITE_SERVER}/admin/orders${id !== null ? `/${id}` : ''}`,
  headers: wmsheader(),
  data: dataToSubmit,
});

export const postReturnOrders = dataToSubmit => axios({
  method: 'POST',
  url: `${SITE_SERVER}/admin/returnorders`,
  headers: wmsheader(),
  data: dataToSubmit,
});

export const saveReturnOrders = (id, dataToSubmit) => axios({
  method: 'PUT',
  url: `${SITE_SERVER}/admin/returnorders/${id}`,
  headers: wmsheader(),
  data: dataToSubmit,
});

export const createReturnOrders = dataToSubmit => axios({
  method: 'POST',
  url: `${SITE_SERVER}/admin/returnorders`,
  headers: wmsheader(),
  data: dataToSubmit,
});

export const requestCarrierService = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/orders/create`,
  headers: wmsheader(),
});


export const postOrderRecipient = dataToSubmit => axios({
  method: 'POST',
  url: `${SITE_SERVER}/order_recipients`,
  headers: wmsheader(),
  data: dataToSubmit,
});

export const autoSearchRecipient = (clientId, txt, limit, page) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/autocomplete/order_recipients?${clientId !== null && typeof clientId !== 'undefined' ? `&client_id=${clientId}&` : ''}limit=${limit}&page=${page}&text=${txt}`,
  headers: wmsheader(),
});

export const importWMSOrders = () => axios({
  method: 'POST',
  url: `${SITE_SERVER}/wms_imports/orders`,
  headers: wmsheader(),
});

export const importWMSOrdersById = dataToSubmit => axios({
  method: 'POST',
  url: `${SITE_SERVER}/wms_imports/by_order_id`,
  headers: wmsheader(),
  data: dataToSubmit,
});

export const lastImportOrders = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/wms_imports/orders`,
  headers: wmsheader(),
});

export const autoSearchClient = (txt, limit, page) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/autocomplete/clients?limit=${limit}&page=${page}&text=${txt}`,
  headers: wmsheader(),
});

export const autoSearchOrders = (txt, status, limit, page) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/autocomplete/orders?limit=${limit}&page=${page}&text=${txt}${status !== null ? `&order_status=${status}` : ''}`,
  headers: wmsheader(),
});

export const requestThirdParty = (
  clientId, status, limit, page, text, start, end, name, company, email,
  sortColumn, sortType,
) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/thirdparty_returnorders?${clientId !== null && typeof clientId !== 'undefined' ? `&client_id=${clientId}&` : ''}status=${status}&limit=${limit}&page=${page}${text !== null ? `&text=${text}` : ''}${start !== null ? `&date_start=${start}` : ''}${end !== null ? `&date_end=${end}` : ''}${name !== null ? `&recipient_name=${name}` : ''}${company !== null ? `&company_name=${company}` : ''}${email !== null ? `&recipient_email=${email}` : ''}${sortColumn !== null ? `&sort_column=${sortColumn}` : ''}${sortType !== null ? `&sort_type=${sortType}` : ''}`,
  headers: wmsheader(),
});

export const createThirdPartyType = dataToSubmit => axios({
  method: 'POST',
  url: `${SITE_SERVER}/admin/thirdparty_returnorder_types`,
  headers: wmsheader(),
  data: dataToSubmit,
});

export const requestThirdPartyForm = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/thirdparty_returnorders/create`,
  headers: wmsheader(),
});

export const postThirdPartyType = dataToSubmit => axios({
  method: 'POST',
  url: `${SITE_SERVER}/admin/thirdparty_returnorder_types`,
  headers: wmsheader(),
  data: dataToSubmit,
});

export const requestThirdPartyDetails = id => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/thirdparty_returnorders/${id}`,
  headers: wmsheader(),
});

export const postThirdPartyReturn = (dataToSubmit, id) => axios({
  method: id !== null ? 'PUT' : 'POST',
  url: `${SITE_SERVER}/admin/thirdparty_returnorders${id !== null ? `/${id}` : ''}`,
  headers: wmsheader(),
  data: dataToSubmit,
});

export const postCSVOrder = dataToSubmit => axios({
  method: 'POST',
  url: `${SITE_SERVER}/admin/orders/loadcsvdata`,
  headers: wmsheader(),
  data: dataToSubmit,
});

export const saveCSVOrder = dataToSubmit => axios({
  method: 'POST',
  url: `${SITE_SERVER}/admin/orders/importcsvdata`,
  headers: wmsheader(),
  data: dataToSubmit,
});
