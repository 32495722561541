/* eslint-disable no-console */
import React, { Component, Fragment } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Menu, MenuItem, Typography } from '@material-ui/core';
import { ReactComponent as ArrowDownSolid } from 'resources/icons/arrow-down-solid.svg';
import styles from './MobileMenuSelectorStyle';

export const dashboardItems = () => {
  const items = [];
  return items;
};

export const salesOrdersItem = () => {
  const items = [
    { label: 'All Orders', route: 'order-fulfillment', url: '/order-fulfillment' },
    { label: 'Pending', route: 'pending-orders', url: '/pending-orders' },
    { label: 'Shipped', route: 'shipped-orders', url: '/shipped-orders' },
    { label: 'Returned', route: 'returned-orders', url: '/returned-orders' },
    { label: 'RA', route: 'return-authorization', url: '/return-authorization' },
    { label: '3rd Party', route: 'third-party', url: '/third-party' },
  ];
  return items;
};

export const orderItems = () => {
  const items = [
    { label: 'Purchase Orders', route: 'purchase-orders', url: '/purchase-orders' },
  ];
  return items;
};

export const supplyReceiptsItems = () => {
  const items = [
    { label: 'Supply Receipts', route: 'supply-receipts', url: '/supply-receipts' },
  ];
  return items;
};

export const supportItems = () => {
  const items = [
    { label: 'Support', route: 'support', url: '/support' },
  ];
  return items;
};

export const manageItems = () => {
  const items = [
    { label: 'Manage Clients', route: 'manage-clients', url: '/manage-clients' },
    { label: 'Manage WMS Access', route: 'manage-access', url: '/manage-access' },
  ];
  return items;
};

export const profileItems = () => {
  const items = [
    { label: 'Profile', route: 'profile', url: '/profile' },
  ];
  return items;
};

export const otherPages = () => {
  const items = [
    { label: 'Manage Calendar', route: 'calendar', url: '/calendar' },
  ];
  return items;
};

class MobileMenuSelector extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { headerStatus, handleLogout } = this.props;
    if (headerStatus === 'expired') {
      handleLogout();
      return true;
    }
    const { classes } = this.props;
    const {
      location, profile,
    } = this.props;
    const loc = location.pathname.split('/', 4, 0);
    let data = [];
    let value = loc[1];
    switch (loc[1]) {
      case '':
      case 'wms':
        data = dashboardItems(profile);
        break;
      case 'order-fulfillment':
        data = salesOrdersItem(profile.role);
        value = 'All Orders';
        break;
      case 'pending-orders':
        data = salesOrdersItem(profile.role);
        value = 'Pending';
        break;
      case 'shipped-orders':
        data = salesOrdersItem(profile.role);
        value = 'Shipped';
        break;
      case 'returned-orders':
        data = salesOrdersItem(profile.role);
        value = 'Returned';
        break;
      case 'return-authorization':
        data = salesOrdersItem(profile.role);
        value = 'RA';
        break;
      case 'third-party':
        data = salesOrdersItem(profile.role);
        value = '3rd Party';
        break;
      case 'purchase-orders':
        data = orderItems(profile.role);
        value = 'Purchase Orders';
        break;
      case 'supply-receipts':
        data = supplyReceiptsItems(profile.role);
        value = 'Supply Receipts';
        break;
      case 'support':
        data = supportItems(profile.role);
        value = 'Support';
        break;
      case 'profile':
        data = profileItems(profile.role);
        value = 'Edit Profile';
        break;
      case 'manage-clients':
        data = manageItems(profile.role);
        value = 'Manage Clients';
        break;
      case 'manage-access':
        data = manageItems(profile.role);
        value = 'Manage WMS Access';
        break;
      case 'calendar':
        data = otherPages();
        value = 'Manage Calendar';
        break;
      default:
        break;
    }
    const items = data;
    // value = value.replace('-', ' ');
    const { anchorEl } = this.state;
    return (
      <Fragment>
        { items.length > 1 ?
          <Button
            className={classes.menu}
            aria-owns={anchorEl ? 'simple-menu' : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            {value} <ArrowDownSolid />
          </Button>
        :
          <Typography className={classes.htitle}>{value}</Typography>
        }
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {items.map(item => (
            <MenuItem
              key={item.label}
              component={NavLink}
              to={item.url}
              onClick={this.handleClose}
              selected={value.toLowerCase() === item.label.toLowerCase()}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      </Fragment>
    );
  }
}

MobileMenuSelector.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  profile: PropTypes.instanceOf(Object), // eslint-disable-line
  headerStatus: PropTypes.string, // eslint-disable-line
  handleLogout: PropTypes.func, // eslint-disable-line
};

export default withRouter(withStyles(styles)(MobileMenuSelector));
