/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, Typography } from '@material-ui/core';
import PageLoader from 'ui/components/loader/PageLoader';
import TableRow from 'ui/components/common/wms/TableRow';
import ErrorBtn from 'ui/components/common/wms/ErrorBtn';
import style from './ListingStyle';
import ListingHead from './ListingHead';

class AddMultipleOrderListing extends Component {
  componentDidMount() {
    const { reviewCSVOrder, orderfile, setloadtype } = this.props;
    setloadtype('review order csv');
    reviewCSVOrder(orderfile);
  }

  componentDidUpdate() {
    const {
      getloadtype, setloadtype, actionloading,
    } = this.props;
    if (!actionloading && getloadtype === 'review order csv') {
      setloadtype('');
    }
  }

  handleRequestSort = (event, property) => {
    console.log(event, property); // eslint-disable-line
  };

  render() {
    const { classes, ...others } = this.props;
    const {
      menus, actionloading, getloadtype, csvorder, sortcolumn, sorttype,
    } = this.props;
    if (actionloading && getloadtype === 'review order csv') {
      return (
        <div className={classes.listingloader}>
          <PageLoader />
        </div>
      );
    }
    if (Object.keys(csvorder).length === 0) {
      return (
        <div className={classes.listingloader}>
          <PageLoader />
        </div>
      );
    }

    return (
      <div className={`csv ${classes.tblwrapperstatic}`}>
        <Table className={`${classes.table} ${classes.tddesktop}`}>
          <ListingHead
            menus={menus.multiorder}
            order={sorttype}
            orderBy={sortcolumn}
            onRequestSort={this.handleRequestSort}
          />
          <TableBody className={`${classes.tbody} ${classes.tbodylist}`}>
            { csvorder.attributes.length ?
              <Fragment>
                {csvorder.attributes.map((order, index) => (
                  <TableRow key={index} >
                    <Fragment>
                      { menus.multiorder.map(menu => (
                        <Fragment key={menu.name}>
                          {menu.name === 'order_no' ?
                            <TableCell align="left" className={classNames(classes.noWrap, classes.tg1)}>{order.order_no}</TableCell>
                          : null }
                          {menu.name === 'po_no' ?
                            <TableCell align="left" className={classNames(classes.noWrap, classes.tg1)}>{order.po_no}</TableCell>
                          : null }
                          {menu.name === 'recipient_name' ?
                            <TableCell align="left" className={classes.tg1}>{order.recipient_name}</TableCell>
                          : null }
                          {menu.name === 'status' ?
                            <TableCell align="left" className={classNames(classes.noWrap, classes.csvstatus, classes.tg1)}>
                              <div className={classes.statusplus}>
                                {Object.keys(order.errors).length === 0 ?
                                  <Typography className="verified">Data verified</Typography>
                                : null }
                                {Object.keys(order.errors).length !== 0 ?
                                  <ErrorBtn {...others} errors={order.errors} />
                                : null }
                              </div>
                            </TableCell>
                          : null }
                        </Fragment>
                      ))}
                    </Fragment>
                  </TableRow>
                ))}
              </Fragment>
            :
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  No results found.
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
        <Table className={`${classes.table} ${classes.tdmobile}`}>
          <TableBody className={classes.tbody}>
            { csvorder.attributes.length ?
              <Fragment>
                {csvorder.attributes.map((order, index) => (
                  <TableRow
                    key={index}
                    className={`${classes.tblrowMobile} ${classes.tblrowMobile4}`}
                  >
                    <TableCell align="left" className={`${classes.noWrap} ${classes.tdleft}`}>
                      <div className={classes.allorders}>
                        { menus.multiorder.map(menu => (
                          <Fragment key={menu.name}>
                            { menu.name === 'order_no' ?
                              <Typography className={classes.txtOrderNo}>
                                {order.order_no}
                              </Typography>
                            : null }
                            { menu.name === 'po_no' ?
                              <Typography className={classes.txtRC}>
                                <label>PO Number:</label> {order.po_no}
                              </Typography>
                            : null }
                            { menu.name === 'recipient_name' ?
                              <Typography className={classes.txtRC}>
                                <label>Recipient:</label> {order.recipient_name}
                              </Typography>
                            : null }
                          </Fragment>
                        ))}
                      </div>
                    </TableCell>
                    <TableCell align="right" className={`${classes.noWrap} ${classes.tdright}`}>
                      { menus.multiorder.map(menu => (
                        <Fragment key={menu.name}>
                          { menu.name === 'status' ?
                            <div className={classes.statusplus}>
                              {Object.keys(order.errors).length === 0 ?
                                <Typography className="verified">Data verified</Typography>
                              : null }
                              {Object.keys(order.errors).length !== 0 ?
                                <ErrorBtn {...others} errors={order.errors} />
                              : null }
                            </div>
                          : null }
                        </Fragment>
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
              </Fragment>
            :
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  No results found.
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </div>
    );
  }
}

AddMultipleOrderListing.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  menus: PropTypes.instanceOf(Object).isRequired,
  actionloading: PropTypes.bool.isRequired,
  setloadtype: PropTypes.func.isRequired,
  getloadtype: PropTypes.string.isRequired,
  searchtxt: PropTypes.string, // eslint-disable-line
  sortcolumn: PropTypes.string.isRequired,
  sorttype: PropTypes.string.isRequired,
  setfilters: PropTypes.func, // eslint-disable-line
  cancelOrder: PropTypes.func, // eslint-disable-line
  reviewCSVOrder: PropTypes.func, // eslint-disable-line
  orderfile: PropTypes.instanceOf(Object), // eslint-disable-line
  // csvorder: PropTypes.instanceOf(Object), // eslint-disable-line
};

export default withStyles(style)(AddMultipleOrderListing);
