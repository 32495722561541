import { bindActionCreators } from 'redux';
import { isItShowingLoad, isItActionShowingLoad, getLoadType, isConfirmed } from 'state/loading/selectors';
import { showDrawer, showModal } from 'state/modal-drawer/selectors';
import { getLoginData, getUserLoginSettings, getSettings } from 'state/logged-user/selectors';
import {
  getOrdersData,
  getReturnedOrdersData,
  getOrderDetails,
  getRAData,
  getDataEntry,
  getRecipientData,
  getCarrierServiceData,
  getOrderTypesData,
  getOrderInfo,
  getFilters,
  getAutoCompleteRecipient,
  getimportInfo,
  getimportOrders,
  getSortColumn,
  getSortType,
  getAutoCompleteClient,
  getAutoCompleteOrders,
  getThirdPartyOrderTypesData,
  getThirdPartyOrderTypesNewData,
  getOrderFile,
  getCSVOrder,
  getImportCSVOrder,
} from 'state/orders/selectors';
import {
  fetchOrderList,
  fetchMoreOrderList,
  fetchOrderDetail,
  fetchOrderItems,
  setRAData,
  setDataEntry,
  fetchReturnOrderList,
  fetchMoreReturnOrderList,
  fetchThirdPartyList,
  fetchMoreThirdPartyList,
  addOrderRecipient,
  getCarrierService,
  setOrderInfo,
  addSalesOrder,
  addReturnOrders,
  updateReturnOrders,
  updateOrders,
  setFilters,
  autocompleteRecipient,
  resetRecipientAutocomplete,
  importsInfo,
  importOrders,
  setSortColumn,
  setSortType,
  updateSalesOrder,
  autocompleteClient,
  resetClientAutocomplete,
  autocompleteOrder,
  resetOrderAutocomplete,
  getThirdPartyForm,
  addThirdPartyType,
  fetchThirdPartyDetail,
  addThirdPartyReturn,
  setOrderFile,
  reviewCSVOrder,
  importCSVOrder,
  clearImportCSVOrder,
} from 'state/orders/actions';
import { setLoadType, setConfirmed } from 'state/loading/actions';
import { getAutoCompleteProduct, getSupplyReceiptClientId, activeClientsData } from 'state/products/selectors';
import { autocompleteProduct, resetProductAutocomplete, setSupplyReceiptClientId, getActiveClients } from 'state/products/actions';
import { getCountries, getAddressData } from 'state/address/selectors';
/* import { setUserSettings } from 'state/logged-user/actions'; */
import * as uiActionCreators from 'state/modal-drawer/actions';
import { fetchClients, handleClientId, fetchSearchClient, resetSearchClient, handleGraphClientId } from 'state/reports/actions';
import { clientsData, getClientId, getGraphClientId, getSearchClient } from 'state/reports/selectors';

export const mapStateToProps = state => ({
  orders: getOrdersData(state),
  returnedorders: getReturnedOrdersData(state),
  loading: isItShowingLoad(state),
  drawer: showDrawer(state),
  modal: showModal(state),
  actionloading: isItActionShowingLoad(state),
  getloadtype: getLoadType(state),
  confirmed: isConfirmed(state),
  detail: getOrderDetails(state),
  radata: getRAData(state),
  dataentry: getDataEntry(state),
  profile: getLoginData(state),
  usersettings: getUserLoginSettings(state),
  autoRecipient: getAutoCompleteRecipient(state),
  countries: getCountries(state),
  addressData: getAddressData(state),
  recipientdata: getRecipientData(state),
  carrierdata: getCarrierServiceData(state),
  ordertypesdata: getOrderTypesData(state),
  formdata: getOrderInfo(state),
  autoProduct: getAutoCompleteProduct(state),
  filtersdata: getFilters(state),
  settings: getSettings(state),
  getimportinfo: getimportInfo(state),
  getimportorders: getimportOrders(state),
  sortcolumn: getSortColumn(state),
  sorttype: getSortType(state),
  clients: clientsData(state),
  clientId: getClientId(state),
  graphClientId: getGraphClientId(state),
  clientlist: getSearchClient(state),
  autoClient: getAutoCompleteClient(state), //
  autoOrders: getAutoCompleteOrders(state),
  SRClientId: getSupplyReceiptClientId(state),
  activeClients: activeClientsData(state), //
  thirdpartyordertypesdata: getThirdPartyOrderTypesData(state),
  thirdpartyordertypesdatanew: getThirdPartyOrderTypesNewData(state),
  orderfile: getOrderFile(state),
  csvorder: getCSVOrder(state),
  importcsvorderdata: getImportCSVOrder(state),
});

export const mapDispatchToProps = dispatch => ({
  orderList: (
    clientId, type, limit, page, text, start, end, name, code, company, email,
    sortColumn, sortType,
  ) => dispatch(fetchOrderList(
    clientId, type, limit, page, text, start, end, name, code, company, email,
    sortColumn, sortType,
  )),
  moreOrderList: (
    clientId, type, limit, page, text, start, end, name, code, company, email,
    sortColumn, sortType,
  ) => dispatch(fetchMoreOrderList(
    clientId, type, limit, page, text, start, end, name, code, company, email,
    sortColumn, sortType,
  )),
  returnOrderList: (
    id, status, limit, page, text, start, end, name, company, email,
    sortColumn, sortType,
  ) => dispatch(fetchReturnOrderList(
    id, status, limit, page, text, start, end, name, company, email,
    sortColumn, sortType,
  )),
  moreReturnOrderList: (
    id, status, limit, page, text, start, end, name, company, email,
    sortColumn, sortType,
  ) => dispatch(fetchMoreReturnOrderList(
    id, status, limit, page, text, start, end, name, company, email,
    sortColumn, sortType,
  )),
  thirdPartyList: (
    id, status, limit, page, text, start, end, name, company, email,
    sortColumn, sortType,
  ) => dispatch(fetchThirdPartyList(
    id, status, limit, page, text, start, end, name, company, email,
    sortColumn, sortType,
  )),
  moreThirdPartyList: (
    id, status, limit, page, text, start, end, name, company, email,
    sortColumn, sortType,
  ) => dispatch(fetchMoreThirdPartyList(
    id, status, limit, page, text, start, end, name, company, email,
    sortColumn, sortType,
  )),
  orderDetail: id => dispatch(fetchOrderDetail(id)),
  ordeItems: ranumber => dispatch(fetchOrderItems(ranumber)),
  actions: { ui: bindActionCreators(uiActionCreators, dispatch) },
  setloadtype: type => dispatch(setLoadType(type)),
  setconfirmed: type => dispatch(setConfirmed(type)),
  setradata: data => dispatch(setRAData(data)),
  setdataentry: data => dispatch(setDataEntry(data)),
  getAutoRecipient: (clientId, txt) => dispatch(autocompleteRecipient(clientId, txt)),
  resetAutoRecipient: () => dispatch(resetRecipientAutocomplete()),
  addrecipient: data => dispatch(addOrderRecipient(data)),
  getCarrier: () => dispatch(getCarrierService()),
  setformdata: data => dispatch(setOrderInfo(data)),
  getAutoProduct: (clinetId, txt) => dispatch(autocompleteProduct(clinetId, txt)),
  resetAutoProduct: txt => dispatch(resetProductAutocomplete(txt)),
  addsalesorder: (data, id) => dispatch(addSalesOrder(data, id)),
  addreturnorders: data => dispatch(addReturnOrders(data)),
  updatereturnorders: (id, data) => dispatch(updateReturnOrders(id, data)),
  updateorders: data => dispatch(updateOrders(data)),
  setfilters: data => dispatch(setFilters(data)),
  /* setsettings: (uid, data) => dispatch(setUserSettings(uid, data)), */
  importsInfo: () => dispatch(importsInfo()),
  importOrders: () => dispatch(importOrders()),
  setSortColumn: column => dispatch(setSortColumn(column)),
  setSortType: type => dispatch(setSortType(type)),
  updatesalesorder: data => dispatch(updateSalesOrder(data)),
  getclients: () => dispatch(fetchClients()),
  setClientId: id => dispatch(handleClientId(id)),
  searchClient: (txt, limit, page) => dispatch(fetchSearchClient(txt, limit, page)),
  resetSearchClient: () => dispatch(resetSearchClient()),
  setGraphClientId: id => dispatch(handleGraphClientId(id)),
  getAutoClient: txt => dispatch(autocompleteClient(txt)),
  resetAutoClient: () => dispatch(resetClientAutocomplete()),
  getAutoOrder: txt => dispatch(autocompleteOrder(txt)),
  resetAutoOrder: () => dispatch(resetOrderAutocomplete()),
  setSupplyReceiptClientId: data => dispatch(setSupplyReceiptClientId(data)),
  getActiveClients: (limit, page) => dispatch(getActiveClients(limit, page)),
  getThirdPartyForm: () => dispatch(getThirdPartyForm()),
  addThirdPartyType: data => dispatch(addThirdPartyType(data)),
  thirdPartyDetail: id => dispatch(fetchThirdPartyDetail(id)),
  addthirdparty: (data, id) => dispatch(addThirdPartyReturn(data, id)),
  setOrderFile: data => dispatch(setOrderFile(data)),
  reviewCSVOrder: data => dispatch(reviewCSVOrder(data)),
  importCSVOrder: data => dispatch(importCSVOrder(data)),
  clearImportCSVOrder: () => dispatch(clearImportCSVOrder()),
});

