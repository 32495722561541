/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Typography, ClickAwayListener } from '@material-ui/core';
import { ReactComponent as Plus } from 'resources/icons/plus.svg';
import { ReactComponent as AddOrder } from 'resources/icons/add-order.svg';
import { ReactComponent as UploadOrder } from 'resources/icons/upload-order.svg';
import { ReactComponent as DownloadOrder } from 'resources/icons/download-order.svg';
import SnackBarNotify from 'ui/components/common/wms/SnackBarNotify';
import styles from './TopBarStyle';

class AddCommonBtn extends Component {
  state = {
    open: false,
  };
  addModal = (e) => {
    e.stopPropagation();
    const { actions } = this.props;
    const params = { type: 'add', action: 'add' };
    const modalKey = 'modal-order';
    actions.ui.openModal({ modalKey, params });
  };

  uploadModal = () => {
    const { actions } = this.props;
    const params = { type: 'addmultipleorder', action: 'addmultipleorder' };
    const modalKey = 'modal-order';
    actions.ui.openModal({ modalKey, params });
  };

  handleClickAway = () => {
    this.setState({ ...this.state, open: false });
  };

  handleCreate = () => {
    this.setState({ ...this.state, open: true });
  };

  validateFile = (obj) => {
    // setErrorFileUpload('');
    if (!obj.files.length) return;

    const allowedExtensions = ['csv'];
    const sizeLimit = 100000000; // 100 MB
    const { name: fileName, size: fileSize } = obj.files[0];
    const fileExtension = fileName.split('.').pop();

    const { setOrderFile, clientId } = this.props;

    if (!allowedExtensions.includes(fileExtension)) {
      document.getElementById('contained-button-file').value = null;
      setOrderFile({});
      return;
    } else if (fileSize > sizeLimit) {
      document.getElementById('contained-button-file').value = null;
      setOrderFile({});
      this.child.showMessage('The File is too large. Allowed maximum size is 100MB', 'error');
      return;
    }
    const datas = new FormData();
    datas.append('file', obj.files[0]);
    datas.append('client_id', clientId);
    setOrderFile(datas);
    this.uploadModal();
  }

  render() {
    const { open } = this.state;
    const { classes, searchType, clientId } = this.props;
    const addTitle = 'Create';

    let stype = '';
    if (typeof searchType !== 'undefined') {
      stype = searchType;
    }

    return (
      <div className={classes.createbtncontainer}>
        <div className={`${classes.addbtn} ${stype}btn`} onClick={this.handleCreate}>
          <IconButton className={classes.iconbtn} aria-label="Add">
            <Plus
              className={classes.icon}
            />
          </IconButton>
          <Typography className={`${classes.addtitle} ${stype}`} component="span">
            {addTitle}
          </Typography>
        </div>
        { open ?
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <div className={classes.addcontainer}>
              <input
                accept=".csv"
                className={classes.ulploadinput}
                id="contained-button-file"
                type="file"
                onChange={e => this.validateFile(e.target)}
                disabled={clientId < 1}
              />
              <ul>
                <li className="header">
                  <strong>Add New Orders</strong>
                </li>
                <li onClick={this.addModal}>
                  <span><AddOrder /></span><label>Add Single Order</label>
                </li>
                <li className={clientId < 1 ? 'disabled' : ''}>
                  <label className="uploadlbl" htmlFor="contained-button-file">&nbsp;</label>
                  <span><UploadOrder /></span><label>Upload Multiple Orders</label>
                </li>
                <li>
                  <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/spreadsheets/d/1ZAHSHqbJQ73x9wyUiElKsYeYDDPlEQQFI9wJDMWrofg/edit?usp=sharing">
                    <span><DownloadOrder /></span>
                    <label className="medium">Download Sample CSV File For Multiple Orders</label>
                  </a>
                </li>
              </ul>
            </div>
          </ClickAwayListener>
        : null }
        <SnackBarNotify onRef={(instance) => { this.child = instance; }} />
      </div>
    );
  }
}

AddCommonBtn.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  searchType: PropTypes.string, // eslint-disable-line
  actions: PropTypes.instanceOf(Object).isRequired,
  setProductsFile: PropTypes.func, // eslint-disable-line
  setOrderFile: PropTypes.func, // eslint-disable-line
  clientId: PropTypes.number.isRequired,
};

export default withStyles(styles)(AddCommonBtn);
